<app-header></app-header>

<main class="content">
  <!-- Start: nav-info-lbl -->
  <div class="article-clean" style="margin-top: 69px;">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
          <!-- Start: Intro -->
          <div class="intro">
            <!-- Start: loc -->
            <p class="text-left"><span class="by">Produtos &gt; ERP</span> </p>
            <!-- End: loc -->
          </div>
          <!-- End: Intro -->
        </div>
      </div>
    </div>
  </div>
  <!-- End: nav-info-lbl -->
  <div class="container">
    <div class="card shadow-lg">
      <div class="card-body">
        <div class="product-header"><img data-bs-hover-animate="bounce" src="../assets/img/LOGO_WINTOUCH_POS.jpg"
            style="width: 236px;">
          <div>
            <h4>ERP<br></h4>
          </div>
        </div>
        <hr>
        <p>O ERP Wintouch é uma ferramenta constituída por diversos módulos escaláveis e configuráveis à realidade das
          empresas, e que permite a gestão do universo de operações que caracterizam a realidade diária das pequenas e
          médias organizações.<br>Garantindo
          um fluxo documental totalmente integrado, o que reduz a um mínimo a necessidade de operações redundantes
          através da ligação automática entre os diversos módulos (libertando os recursos necessários à gestão diária) o
          ERP é também o
          suporte de toda a informação de natureza legal e fiscal (através dos seus módulos de Contabilidade, Recursos
          Humanos e Imobilizado), havendo uma recolha totalmente automatizada de toda a informação necessária à
          apresentação perante
          a administração fiscal.<br>Esta solução caracteriza-se por uma absoluta uniformidade de utilização e de
          interface em todos os seus componentes, reduzindo tempos de formação e melhorando a experiência de utilização,
          com os consequentes
          ganhos de produtividade.<br></p>
      </div>
    </div>
  </div>
  <!-- Start: 1 Row 1 Column -->
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow-lg" style="margin: 10px;">
            <div class="card-body">
              <div class="product-header"><img src="../assets/img/wintouch-s14.png">
                <div class="d-lg-flex justify-content-lg-center align-items-lg-center">
                  <h5>Gestão Comercial<br></h5>
                </div>
              </div>
              <hr>
              <ul>
                <li>Facturação<br></li>
                <li>Gestão de Fornecedores e Aprovisionamento<br></li>
                <li>Contas Correntes<br></li>
                <li>Cobranças e Tesouraria<br></li>
                <li>Stocks e Inventários<br></li>
                <li>Gestão de Produção<br></li>
                <li>Gestão de Vendedores e Comissionistas<br></li>
                <li>Avenças<br></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow-lg" style="margin: 10px;">
            <div class="card-body">
              <div class="product-header"><img src="../assets/img/wintouch-s15.png">
                <div class="d-lg-flex justify-content-lg-center align-items-lg-center">
                  <h5>Contabilidade<br></h5>
                </div>
              </div>
              <hr>
              <p class="text-justify card-text">Solução que permite o tratamento informatizado de Contabilidades
                organizadas. Esta aplicação reponde de forma eficaz aos desafios impostos pela administração fiscal
                dando, em simultâneo, toda a informação de gestão necessária
                ao dia a dia.<br></p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow-lg" style="margin: 10px;">
            <div class="card-body">
              <div class="product-header"><img src="../assets/img/wintouch-s16.png">
                <div class="d-lg-flex justify-content-lg-center align-items-lg-center">
                  <h5>Imobilizado<br></h5>
                </div>
              </div>
              <hr>
              <p class="text-justify card-text">Aplicação que para além do tratamento do ciclo de vida de um Bem do
                Imobilizado, emissão de mapas fiscais, de processamentos de amortizações e reavaliações, pretende também
                ser uma ferramenta que auxilie o responsável pela
                gestão do imobilizado de uma empresa na sua tomada de decisão.<br></p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow-lg" style="margin: 10px;">
            <div class="card-body">
              <div class="product-header"><img src="../assets/img/wintouch-s17.png">
                <div class="d-lg-flex justify-content-lg-center align-items-lg-center">
                  <h5>Gestão de Pessoal<br></h5>
                </div>
              </div>
              <hr>
              <p class="text-justify card-text">É uma aplicação que, para além do processamento dos vencimentos dos
                Funcionários e Independentes, da satisfação das obrigações fiscais, pretende também ser uma ferramenta
                que auxilie o responsável pela gestão dos recursos humanos
                de uma empresa nas suas tomadas de decisão.<br></p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow-lg" style="margin: 10px;">
            <div class="card-body">
              <div class="product-header"><img src="../assets/img/wintouch-s18.png">
                <div class="d-lg-flex justify-content-lg-center align-items-lg-center">
                  <h5>Mobile Sales<br></h5>
                </div>
              </div>
              <hr>
              <p class="text-justify card-text">É um produto destinado à gestão dos serviços de pré-venda e auto-venda
                das forças de venda móveis das empresas. Totalmente integrado com WINTOUCH Comercial e desenvolvido com
                as mais recentes tecnologias, permite gerir de forma
                eficaz a força de vendas móvel de uma empresa simplificando os processos de controlo e gestão de
                encomendas de clientes, vendas e cobranças.<br></p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow-lg" style="margin: 10px;">
            <div class="card-body">
              <div class="product-header"><img src="../assets/img/wintouch-s19.png">
                <div class="d-lg-flex justify-content-lg-center align-items-lg-center">
                  <h5>Assistências Técnicas<br></h5>
                </div>
              </div>
              <hr>
              <p class="text-justify card-text">É uma aplicação destinada a empresas que prestam serviços de assistência
                técnica, manutenção e reparação. Para além de permitir a gestão de todo o parque de equipamentos e
                acessórios, dos números de séries, das garantias e
                contratos de manutenção, fornece ainda todos os mecanismos de controlo da equipa de técnicos. É dotado
                de um completo conjunto de quadros de gestão, que fornecem detalhadas análises da rentabilidade da
                empresa sob os mais
                variados critérios.<br></p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow-lg" style="margin: 10px;">
            <div class="card-body">
              <div class="product-header"><img src="../assets/img/wintouch-s20.png">
                <div class="d-lg-flex justify-content-lg-center align-items-lg-center">
                  <h5>Gestão Documental<br></h5>
                </div>
              </div>
              <hr>
              <p class="text-justify card-text">É uma aplicação que lhe permite armazenar, de forma digital, os mais
                variados documentos da empresa. A aquisição dos documentos pode ser efectuada usando os vários meios
                existentes hoje em dia (scanners, máquinas fotográficas,
                webcam's, etc...)<br></p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow-lg" style="margin: 10px;">
            <div class="card-body">
              <div class="product-header"><img src="../assets/img/sms1.logo.png">
                <div class="d-lg-flex justify-content-lg-center align-items-lg-center">
                  <h5>Gestão de SMS<br></h5>
                </div>
              </div>
              <hr>
              <p class="text-justify card-text">É uma aplicação que lhe permite fazer a gestão do envio de mensagens e
                promoções através de SMS. Numa perspectiva de CRM, o SMS é hoje em dia uma ferramenta fundamental na
                manutenção da relação com os seus clientes. Por ter
                um custo baixo, ser quase virtualmente ubíquo e universal e por permitir feed-back, este mecanismo de
                comunicação faz hoje parte de qualquer ERP.<br></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End: 1 Row 1 Column -->
</main>

<app-footer></app-footer>
