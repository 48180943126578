<app-header></app-header>

<main class="content">
  <!-- Start: Article Clean -->
  <div class="article-clean">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
          <!-- Start: Intro -->
          <div class="intro">
            <h1 class="text-center">Politica de privacidade e cookies</h1>
          </div>
          <!-- End: Intro -->
          <!-- Start: Text -->
          <div class="text">
            <h3 style="text-decoration: underline;">
              Política de Privacidade
            </h3>

            <p>
              A presente política de privacidade que abrange este website respeita a privacidade dos Clientes e somente processa os seus dados pessoais para efeitos de processamento dos pedidos dos Clientes (Encomendas, Faturas de Venda, pedidos de informação).
            </p>

            <p>
              Os dados não serão processados para quaisquer outros fins que não os aqui especificados.
            </p>

            <p>
              Não serão utilizados os dados pessoais para enviar qualquer correio não solicitado cujo conteúdo não tenha recebido o consentimento explícito.
            </p>

            <p>
              A Porto de Informática, Lda não divulga/partilha a terceiros quaisquer dados pessoais, exceto quando tal for exigido por lei.
            </p>

            <p>
              Os dados são guardados nos nossos servidores locais protegidos de acordo a respeitar as leis de privacidade aplicáveis.
            </p>

            <h3 style="text-decoration: underline;">
              Cookies
            </h3>

            <p>
              O nosso website utiliza cookies que são ficheiros que armazenam informações no disco rígido ou browser do Utilizador, permitindo que os websites o reconheçam e saibam que já os visitou anteriormente.
            </p>

            <p>
              O Utilizador pode configurar o seu browser para recusar os cookies, porém nesse caso, o website ou partes do mesmo podem não funcionar corretamente.
            </p>

            <p>
              Os cookies são utilizados para monitorizar e analisar a utilização do website.
            </p>

            <p>
              A PORTO DE INFORMÁTICA, LDA. não dá qualquer uso aos cookies gerados neste website
            </p>

          </div>
          <!-- End: Text -->
        </div>
      </div>
    </div>
  </div>
  <!-- End: Article Clean -->
  <main>
