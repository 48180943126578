<app-header></app-header>

<main class="content">
  <!-- Start: nav-info-lbl -->
  <div class="article-clean" style="margin-top: 69px;">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
          <!-- Start: Intro -->
          <div class="intro">
            <!-- Start: loc -->
            <p class="text-left"><span class="by">Produtos &gt; Clinicas</span> </p>
            <!-- End: loc -->
          </div>
          <!-- End: Intro -->
        </div>
      </div>
    </div>
  </div>
  <!-- End: nav-info-lbl -->
  <div class="container">
    <div class="card shadow-lg">
      <div class="card-body">
        <div class="product-header"><img data-bs-hover-animate="bounce" src="../assets/img/LOGO_WINTOUCH_POS.jpg"
            style="width: 236px;">
          <div>
            <h4>Clínicas Médicas &amp; Hospitalares<br>Veterinárias<br></h4>
          </div>
        </div>
        <hr>
      </div>
    </div>
    <div class="card shadow-lg">
      <div class="card-body">
        <div class="product-header"><img data-bs-hover-animate="bounce" src="../assets/img/wintouch-s11.png">
          <div>
            <h4>Clinicas<br></h4>
            <h6 class="text-muted mb-2">Médicas &amp; Hospitalares<br></h6>
          </div>
        </div>
        <hr>
        <p class="text-justify card-text">WINTOUCH Clínicas, é um avançado sistema de gestão e informação médica, de
          fácil uso, destinado a Consultórios, Clínicas Médicas e Clínicas Hospitalares de Pequena e Média
          dimensão.<br><br>Representa a ferramenta indispensável e ideal
          para gerir consultórios/clínicas das mais variadas áreas de actividade como Cardiologia, Clínica Geral,
          Dermatologia, Ginecologia/Obstetrícia, Oftalmologia, Ortopedia, Otorrinolaringologia, Psicologia, Psiquiatria,
          Urologia, respondendo
          ainda às necessidades específicas de outras especialidades tais como Fisioterapia, Dentária,
          Estética/Nutricionismo, Podologia, Pediatria, Centros de Exames e Hospitalização.<br><br>O aspecto principal
          desta aplicação é o facto da
          componente de facturação e relação com os subsistemas de saúde (eg. ADSE) e seguradoras ser totalmente
          automatizado. Este facto é por si só um elemento fundamental na aquisição do sistema deste tipo, pois para
          além de passar a estar
          dotado de uma ferramenta que automatiza as rotinas diárias da clínica (marcações, consultas, dossier do
          paciente), elimina totalmente a possibilidade de erros no envio das facturas às diversas entidades, com os
          consequentes benefícios
          financeiros e a diminuição do tempo de reembolso.<br><br>Características principais<br>Dentária<br>Estética /
          Nutricionismo<br>Podologia<br>Pediatria<br>Fisioterapia<br>Centros de Exames<br>Hospitalização /
          Internamento<br><br></p>
        <hr>
      </div>
    </div>
    <div class="card shadow-lg">
      <div class="card-body">
        <div class="product-header"><img data-bs-hover-animate="bounce" src="../assets/img/wintouch-s12.png">
          <div>
            <h4>Clinicas<br></h4>
            <h6 class="text-muted mb-2">Veterinárias<br></h6>
          </div>
        </div>
        <hr>
        <p class="text-justify card-text">WINTOUCH Veterinárias, é uma completa aplicação destinada à gestão de clínicas
          veterinárias.<br>Através de um intuitivo e poderoso interface, esta solução permite planear e gerir todos os
          processos clínicos e administrativos da sua clínica
          veterinária. Assentando sobre a mesma plataforma partilhada pelas restantes aplicações da nossa linha de
          produtos de gestão, disponibiliza o mesmo conjunto de poderosas ferramentas globais a todas as nossas
          soluções.<br></p>
        <hr>
      </div>
    </div>
  </div>
</main>

<app-footer></app-footer>
