import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { NewsLetterService, INewsletterServiceMessage } from '../services/news-letter.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {

  constructor(private newsLetterService: NewsLetterService) { }

  // Subscrição do serviço newsletter
  private nlsSubs: Subscription;
  // Resultado da subscrição
  newsLetterResult: INewsletterServiceMessage;
  // loader
  loading: boolean;

  cookies: boolean;

  ngOnInit() {

    var cookie:String = sessionStorage.getItem('PortoInfCookies');

    if(cookie != null) {
      this.cookies = true;
    }

    this.nlsSubs = this.newsLetterService.commResultObs.subscribe(result => {
      this.loading = false;
      if (!result) return;
      this.newsLetterResult = result;
    });
  }

  nlSubscribe(email: string) {
    this.newsLetterService.join(email);
    this.loading = true;
  }

  clearMessages() {
    this.newsLetterResult = null;
  }

  acceptCookies() {
    sessionStorage.setItem('PortoInfCookies', 'true');
    this.cookies = true;
  }

  ngOnDestroy() {
    this.nlsSubs.unsubscribe();
  }

}
