<app-header></app-header>

<main class="content">
  <!-- Start: nav-info-lbl -->
  <div class="article-clean" style="margin-top: 69px;">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
          <!-- Start: Intro -->
          <div class="intro">
            <!-- Start: loc -->
            <p class="text-left"><span class="by">Produtos &gt; Hotelaria</span> </p>
            <!-- End: loc -->
          </div>
          <!-- End: Intro -->
        </div>
      </div>
    </div>
  </div>
  <!-- End: nav-info-lbl -->
  <div class="container">
    <div class="card shadow-lg">
      <div class="card-body">
        <div class="product-header"><img data-bs-hover-animate="bounce" src="../assets/img/wintouch-s10.png">
          <div>
            <h4>Software Wintouch<br></h4>
            <h6 class="text-muted mb-2">Hotelaria PMS<br></h6>
          </div>
        </div>
        <hr>
        <p class="text-justify card-text">WINTOUCH Hotelaria é um ERP destinado à gestão de unidades hoteleiras de
          pequena e média dimensão.<br><br>-Por possuir poderosos e intuitivos mecanismos de gestão de reservas,
          preçários e facturação....-Por contemplar todos os interfaces
          correntes nas unidades hoteleiras como ligações a Pontos de Venda, chaves de abertura de portas, ligação a
          sistemas on-line de reservas....<br><br>-Por disponibilizar gestão multi-unidade, que lhe permite gerir em
          simultâneo as disponibilidades
          de uma cadeia de hotéis....<br><br>-Por estar interligado às restantes aplicações WINTOUCH como WINTOUCH
          Contabilidade, WINTOUCH Recursos Humanos, WINTOUCH Restauração ou WINTOUCH Retalho...<br><br>...esta solução
          posiciona-se como
          um instrumento de gestão fundamental à unidades hoteleiras, independentemente da sua dimensão.<br>Como se
          poderá comprovar pelas características do produto, são vários os mecanismos que visam facilitar a tarefa ao
          operador. Quer pela
          utilização de mecanismos próprios deste programa quer pela utilização de mecanismos comuns disponíveis, por
          exemplo, no WINTOUCH Manager ou WINTOUCH Comercial.<br><br>Modulos Adicionais:<br>&nbsp;- Ligação a central
          telefónica<br>&nbsp;-
          Ligação a KEYCARDS (interface acesso aos quartos)<br>&nbsp;- Ligação IPTV (Nonius)<br>&nbsp;- Ligação a
          Booking<br>&nbsp;- Ligação a Web Services (Integração entre HOTELARIA e sistemas de Reservas On-Iine)<br></p>
        <hr>
      </div>
    </div>
  </div>
</main>

<app-footer></app-footer>
