<app-header></app-header>

<main class="content">
  <!-- Start: nav-info-lbl -->
  <div class="article-clean" style="margin-top: 69px;">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
          <!-- Start: Intro -->
          <div class="intro">
            <!-- Start: loc -->
            <p class="text-left"><span class="by">Produtos &gt; Bares/Discotecas</span> </p>
            <!-- End: loc -->
          </div>
          <!-- End: Intro -->
        </div>
      </div>
    </div>
  </div>
  <!-- End: nav-info-lbl -->
  <div class="container">
    <div class="card shadow-lg">
      <div class="card-body">
        <div class="product-header"><img data-bs-hover-animate="bounce" src="../assets/img/xd-s7.png">
          <div>
            <h4>Software XD<br></h4>
            <h6 class="text-muted mb-2">Bares e Discotecas<br></h6>
          </div>
        </div>
        <hr>
        <p class="text-justify card-text">XD DISCO - O mais avançado software para Gestão de Discotecas e
          Bares.<br><br>1 Programa - 4 Sistemas.<br><br>Imagine uma discoteca em plena hora de pico com mais de 5000
          clientes a efectuar registos e pagamentos, o XD Disco apresenta-se
          como a mais fiável solução para Discotecas e Bares com opções únicas e direccionadas que o elevarão para
          software líder do sector.<br><br></p>
        <hr>
      </div>
    </div>
  </div>
  <!-- Start: 1 Row 2 Columns -->
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <div class="card shadow-lg" style="margin: 10px;">
            <div class="card-body">
              <div class="product-header"><img src="../assets/img/xd-s8.png">
                <div class="d-lg-flex justify-content-lg-center align-items-lg-center">
                  <h5>Sistema de cartões Online<br></h5>
                </div>
              </div>
              <hr>
              <p class="text-justify card-text">O tradicional sistema para cartões com banda magnética ou código de
                barras.<br></p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card shadow-lg" style="margin: 10px;">
            <div class="card-body">
              <div class="product-header"><img src="../assets/img/xd-s9.png">
                <div class="d-lg-flex justify-content-lg-center align-items-lg-center">
                  <h5>Sistema de caixas de Pagamento<br></h5>
                </div>
              </div>
              <hr>
              <p class="text-justify card-text">Para quem usa cartões de papel e usa apenas sistema de registo no
                caixa.<br></p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="card shadow-lg" style="margin: 10px;">
            <div class="card-body">
              <div class="product-header"><img src="../assets/img/xd-s10.png">
                <div class="d-lg-flex justify-content-lg-center align-items-lg-center">
                  <h5>Sistema de Pagamento no Acto<br></h5>
                </div>
              </div>
              <hr>
              <p class="text-justify card-text">Uso em discotecas onde o cliente paga no ato o seu consumo.<br><br></p>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card shadow-lg" style="margin: 10px;">
            <div class="card-body" style="margin: 0px;">
              <div class="product-header"><img src="../assets/img/xd-s11.png">
                <div class="d-lg-flex justify-content-lg-center align-items-lg-center">
                  <h5>Sistema Hibrido com Restaurante<br></h5>
                </div>
              </div>
              <hr>
              <p class="text-justify card-text">Para casas que possuem discoteca e restaurante. Registo em mesas, uso de
                impressoras de cozinha.<br></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End: 1 Row 2 Columns -->
  <!-- Start: Highlight Clean -->
  <div class="highlight-clean">
    <div class="container">
      <!-- Start: Intro -->
      <div class="intro">
        <h4 class="text-center">Opções específicas de discotecas<br></h4>
        <p class="text-center"><br>Terminal de Porta, Terminal de Consulta, Módulo de registo no bar, Escritório módulo
          online, Terminal de caixa, Dispositivos móveis e internet, Ofertas Noite da Mulher, Sistema avançado de
          Ofertas , Happy Hour (preços especiais por
          hora), Séries de Cartões , Controle de mesas na discoteca, Controle de pagamento à entrada , Módulo Check in,
          Bloqueio de cartões , Cartões pré-pagos, Controle de Anulações, Consumos mínimos, Talão Online de conferência,
          Controle de
          Bengaleiro , Vários tipos de cartão, Mapas específicos de Discoteca, Stocks, Pagamento de Múltiplos cartões ,
          Clientes, Leitura de cartões de bandas magnética com Prefixo e Sufixo de cartões com "Wildcards".<br><br></p>
      </div>
      <!-- End: Intro -->
      <!-- Start: Buttons -->
      <div class="buttons"></div>
      <!-- End: Buttons -->
    </div>
  </div>
</main>

<app-footer></app-footer>
