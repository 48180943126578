<app-header></app-header>

<main class="content">
  <!-- Start: nav-info-lbl -->
  <div class="article-clean" style="margin-top: 69px;">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
          <!-- Start: Intro -->
          <div class="intro">
            <!-- Start: loc -->
            <p class="text-left"><span class="by">Produtos &gt; Restauração</span> </p>
            <!-- End: loc -->
          </div>
          <!-- End: Intro -->
        </div>
      </div>
    </div>
  </div>
  <!-- End: nav-info-lbl -->
  <!-- Start: 1 Row 2 Columns -->
  <div>
    <div class="container">
      <div class="row">

        <div class="col-md-12">
          <div class="card shadow-lg">
            <div class="card-body">
              <div class="product-header"><img data-bs-hover-animate="bounce" src="../assets/img/wintouch_rest.png">
                <div>
                  <h4>Software Wintouch<br></h4>
                  <h6 class="text-muted mb-2">Restauração</h6>
                </div>
              </div>
              <hr>
              <p class="text-justify card-text">WINTOUCH Restauração, é actualmente uma referência nas soluções
                disponíveis no mercado para as áreas de Restauração, Panificação, Bares, Discotecas, Catering e Gestão
                F&amp;B.<br><br>Como aspecto principal desta solução destaca-se
                a enorme facilidade de utilização que permite eliminar longos tempos de
                formação.<br><br>Disponibilizando um Frontoffice intuitivo, rápido e completo, esta solução está 100%
                interligada com as restantes componentes do ERP
                Wintouch disponibilizando o mais completo sistema do mercado.<br><br></p>
              <hr>
              <div class="wintouch-rest-items">
                <div class="text-center">
                  <h6>Mesas Balcao</h6><img src="../assets/img/mesas-balcao.png">
                </div>
                <div class="text-center">
                  <h6>Panificadoras</h6><img src="../assets/img/panif.png">
                </div>
                <div class="text-center">
                  <h6>Catering</h6><img src="../assets/img/wintouch-s3.png">
                </div>
                <div class="text-center">
                  <h6>Fast-food</h6><img src="../assets/img/wintouch-s4.png">
                </div>
                <div class="text-center">
                  <h6>Delivery</h6><img src="../assets/img/wintouch-s5.png">
                </div>
                <div class="text-center">
                  <h6>Bares/Disco</h6><img src="../assets/img/wintouch-s6.png">
                </div>
                <div class="text-center">
                  <h6>Term. Portateis</h6><img src="../assets/img/wintouch-s7.png">
                </div>
                <div class="text-center">
                  <h6>Dual display</h6><img src="../assets/img/wintouch-s8.png">
                </div>
                <div class="text-center">
                  <h6>Filiais</h6><img src="../assets/img/wintouch-s9.png">
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="col-md-6">
          <div class="card shadow-lg">
            <div class="card-body">
              <div class="product-header"><img data-bs-hover-animate="bounce" src="../assets/img/logo-xd-.png">
                <div>
                  <h4>Software XD<br></h4>
                  <h6 class="text-muted mb-2">Restauração</h6>
                </div>
              </div>
              <hr>
              <p class="text-justify card-text">XD - O mais completo software do mercado para gestão de Restaurantes,
                Cafeterias, Pizzarias e Confeitarias, preparado para as mais complexas configurações dos
                estabelecimentos, o XD Rest é intuitivo no registo e poderoso na
                Gestão.<br><br>Fléxivel<br>Permite a instalação em ambiente Touch Screen e/ou Teclado. Criar e
                configurar salas com mesas e objetos realistas, permitindo definir Ivas e preços diferentes por
                zonas.<br><br></p>
              <hr>
              <div class="d-flex justify-content-center"><img src="../assets/img/xd-s1.png"
                  style="margin-right: 16px;"><img src="../assets/img/xd-s2.png" style="margin-left: 16px;"></div>
              <p class="text-justify"><br>Fast Food/Módulo Delivery/Pizzaria<br>Definição de menus por etapas tipo
                Fast-Food ou registo automático tipo Menu do Dia<br>Realiza gestão de pedidos por atendimento telefónico
                para entregas ao domicílio ou Take-Away.<br>AIRMENU/
                Ligação a dispositivos de pedidos<br><br></p>
              <div class="d-flex justify-content-center"><img src="../assets/img/xd-s3.png"
                  style="margin-right: 16px;"><img src="../assets/img/xd-s4.png" style="margin-left: 16px;"></div>
            </div>
          </div>
        </div> -->

      </div>
    </div>
  </div>
  <!-- End: 1 Row 2 Columns -->
</main>

<app-footer></app-footer>
