import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoaderComponent } from './loader/loader.component';
import { FooterComponent } from './footer/footer.component';
import { ContactsComponent } from './contacts/contacts.component';
import { HeaderComponent } from './header/header.component';
import { RestComponent } from './products/rest/rest.component';
import { RetComponent } from './products/ret/ret.component';
import { HotComponent } from './products/hot/hot.component';
import { BdComponent } from './products/bd/bd.component';
import { HealthComponent } from './products/health/health.component';
import { ErpComponent } from './products/erp/erp.component';
import { CctvComponent } from './products/cctv/cctv.component';
import { CopyrightComponent } from './copyright/copyright.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoaderComponent,
    FooterComponent,
    ContactsComponent,
    HeaderComponent,
    RestComponent,
    RetComponent,
    HotComponent,
    BdComponent,
    HealthComponent,
    ErpComponent,
    CctvComponent,
    CopyrightComponent,
    PrivacyComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
