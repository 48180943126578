<app-header></app-header>

<main class="content">
  <!-- Start: Article Clean -->
  <div class="article-clean">
    <div class="container">
      <div class="row">
        <div class="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
          <!-- Start: Intro -->
          <div class="intro">
            <h1 class="text-center">Termos e condições</h1>
          </div>
          <!-- End: Intro -->
          <!-- Start: Text -->
          <div class="text">

            <h3 style="text-decoration: underline;">
              Condições Gerais de Utilização
            </h3>

            <p>
              A Porto de Informática, Lda., com sede na Rua Afonso III s/n, em Vila Nova de Gaia com nº. matrícula e identificação fiscal 503831018, Telefone: 225089801 (Chamada para a rede fixa Nacional), e-mail: geral@portoinf.com, incorporou no seu sítio www.portoinf.com, uma loja virtual em que os clientes maiores de idade, podem adquirir produtos novos, recondicionados, serviços e equipamentos em segunda mão, propostos a um preço fixo.
            </p>

            <p>
              Para utilizar o Serviço, o Comprador terá de aceitar e sujeitar-se às presentes Condições Gerais de Utilização sem restrições nem reservas. Esta aceitação consubstancia-se com a confirmação no momento da compra.
            </p>

            <p>
              Qualquer compra dos nossos produtos ou serviços através deste website representa um contrato de compra efetivo com a PORTO DE INFORMÁTICA, LDA.
            </p>

            <p>
              Se o cliente não está 100% seguro de que deseja concluir o negócio, não o deve efetuar.
            </p>

            <h3 style="text-decoration: underline;">
              Preços
            </h3>

            <p>
              Pelo facto de a Porto de Informática, Lda. ser uma empresa especialmente dedicada á venda de equipamentos e serviços a empresas, os preços do website NÃO são apresentados com IVA à taxa legal em vigor, o qual será adicionado na finalização da encomenda.
            </p>

            <h3 style="text-decoration: underline;">
              Processo de compra
            </h3>

            <p>
              Após a confirmação do pagamento (incluindo o método de entrega/envio escolhido), os equipamentos são enviados num prazo de 2 a 7 dias úteis após confirmação do pagamento recebido.  No caso de um serviço, o mesmo será realizado num prazo de 1 a 4 dias uteis.
            </p>

            <p>
              Em caso de impossibilidade de satisfazer esses prazos (por motivo de forma maior), o valor da compra será devolvido, se o cliente o pretender.
            </p>

            <h3 style="text-decoration: underline;">
              Envio, Entrega e realização de Serviços
            </h3>

            <p>
              A Porto de Informática, Lda. efetua os envios por transportadora, para qualquer morada de Portugal Continental ou Insular. Os portes de envio, serão suportados pelo Cliente e variam de acordo com o peso/volume da encomenda. Para Encomendas superiores a 500,00€ + IVA, A Porto de Informática, Lda. suporta 50% do valor dos portes. Acima dos 1.000,00€ + IVA, os portes são oferecidos. Esta oferta é válida para Portugal Continental, e excluem-se encomendas superiores a 30Kg,
            </p>

            <p>
              Em alternativa o cliente pode efetuar a recolha da encomenda.
            </p>

            <p>
              Os equipamentos são embalados de forma a proporcionar as melhores condições de envio possíveis.
            </p>

            <p>
              O Cliente, ao receber a encomenda, deve na presença do transportador, confirmar o bom estado da mesma. No caso de a mesma não estar em conformidade o Cliente na presença do transportador, deve abrir as mesmas e verificar o seu conteúdo. É obrigação do Cliente, registar qualquer anomalia fazendo prova se possível com fotografias.
            </p>

            <p>
              No caso da realização se serviços técnicos, podem os mesmos ser efetuados na morada indicada pelo cliente ou efetuados remotamente.
            </p>

            <h3 style="text-decoration: underline;">
              Âmbito do direito de livre resolução
            </h3>

            <p>
              O direito de livre resolução encontra-se regulamentado de igual modo nos contratos celebrados à distância e nos contratos celebrados fora do estabelecimento comercial, sendo o prazo para o respetivo exercício, de 14 dias seguidos.
            </p>

            <p>
              Caso o cliente pretenda, por algum motivo, devolver ou trocar o artigo, tem um prazo de 14 dias para o solicitar através de e-mail ou carta dessa pretensão.
            </p>

            <p>
              A devolução/troca só é aceite se o equipamento for devolvido na embalagem original, nas mesmas condições nas quais foi vendido.
            </p>

            <p>
              Os portes de envio nas situações de livre resolução (devolução ou troca sem justificação válida) ou fora dos âmbitos da Garantia são da inteira responsabilidade do cliente.
            </p>

            <p>
              Ainda no âmbito da livre resolução, no caso de prestação de serviços de assistência técnica, o cliente deve apresentar o pedido expresso através de e-mail ou carta, caso queira que a prestação do serviço se inicie durante o prazo em que decorre o exercício daquele direito, sendo que se o cliente, ainda assim, vier a exercer o direito de livre resolução deve pagar um montante proporcional ao que for efetivamente prestado.
            </p>

            <h3 style="text-decoration: underline;">
              Centro de Arbitragem e RAL ( Resolução Alternativa de Litígios de Consumo)
            </h3>

            <p>
              <strong style="text-decoration: underline;">
                Resolução alternativa de litígios
              </strong>
            </p>

            <p>
              Em caso de litígios que digam respeito a obrigações contratuais resultantes de contratos de venda ou prestação de serviços, celebrados entre a nossa empresa e os consumidores residentes em Portugal e na União Europeia, o consumidor pode recorrer a uma Entidade de Resolução de Litígios de Consumo.
            </p>

            <p>
              A Porto de Informática, Lda. recorre em Portugal à seguinte entidade de Resolução de Litígios de Consumo:
            </p>

            <p>
              <strong>
                Centro de Informação de Consumo e Arbitragem do Porto
              </strong> <br>
              Rua Damião de Góis, 31, Loja 6, 4050-225 Porto <br>
              Telefone: +351 22 550 83 49 / +351 22 502 97 91 | Fax: +351 22 502 61 09 <br>
              E-mail: cicap@cicap.pt
            </p>

            <p>
              Para Queixas e Reclamações relativas a compras e serviços efetuadas poderá contactar através do email geral@portoinf.com ou através do telefone 225089801 (chamada para rede fixa nacional) das 10h às 13h e das 15H ás 19h de segunda a sexta.
            </p>

            <p>
              Para mais informações, consulte o site da Direção-Geral do Consumidor, em <a href="http://www.consumidor.pt">www.consumidor.pt</a>
            </p>

            <h3 style="text-decoration: underline;">
              Equipamentos Usados/Recondicionados
            </h3>

            <p>
              Estes equipamentos vendidos neste website são equipamentos que fizeram parte de parques informáticos que foram renovados. São equipamentos verificados, limpos, testados e configurados e renovados com software legal e genuíno.
            </p>

            <h3 style="text-decoration: underline;">
              Serviços de assistência Técnica
            </h3>

            <p>
              São Contratos de serviços de assistência técnica pré-pagos, disponibilizados ao cliente em pacotes de 5, 10 ou 15 horas.
            </p>

            <p>
              Estas intervenções técnicas podem ser prestadas na morada do cliente ou remotamente.
            </p>

            <p>
              O contrato é intransmissível.
            </p>

            <p>
              Os serviços previstos consistirão em intervenções a pedido do cliente quando ocorram avarias decorrentes do normal uso do equipamento, podendo ser efetuadas das seguintes formas:
            </p>

            <p>
              Assistência técnica telefónica (das 8:00 ás 02:00) – (Período Mínimo de assistência: 0:15h)
            </p>

            <p>
              Assistência técnica remota por telemanutenção (das 10:00 ás 02:00) - – (Período Mínimo de assistência: 0:30h)
            </p>

            <p>
              Assistência técnica na morada do cliente durante o horário de expediente normal da Porto de Informática, Lda., que são das 10.00 ás 19.00 de Segunda a sexta-feira, e através do serviço de piquete das 19.00 às 02.00 de Segunda a Sexta-feira e das 11.00 às 02:00 aos Sábados, Domingos e Feriados. A deslocação ao local da intervenção não exclui a possibilidade de a mesma ser realizada nas instalações da Porto de Informática, Lda. sempre que por razões de ordem técnica o justifiquem. – (Período Mínimo de assistência: 1:00h). Cada Deslocação no local tem o custo adicional.
            </p>

            <p>
              O serviço de piquete das 19.00 às 24.00 de Segunda a Sexta-feira e das 11.00 às 24.00 aos Sábados, Domingos e feriados, o tempo de intervenção é consideradas a triplicar.
            </p>

            <p>
              A Porto de Informática, Lda. Poderá fazer-se substituir por terceiros a fim de cumprir as suas obrigações, disponibilizando os meios técnicos ou humanos que considere necessários.
            </p>

            <h3 style="text-decoration: underline;">
              Garantias
            </h3>

            <p>
              Nos termos do previsto pelo Decreto-Lei n.º 84/2021 de 18 de outubro (documento que regula os direitos do consumidor na compra e venda de bens, conteúdos e serviços digitais, transpondo as Diretivas (UE) 2019/771 e (UE) 2019/770), todos os equipamentos comercializados desde o dia 01/01/2022 estão abrangidos por uma garantia de 3 anos (novos e recondicionados), exceto para os bens em segunda mão/Recondicionados, cujo prazo de garantia é de 18 meses a contar da respetiva entrega ao cliente, desde que cumpridas as condições de garantia da marca. Em resumo:
            </p>

            <p>
              <span style="margin-left: 50px;">
                Equipamentos novos
              </span>
            </p>

            <p>
              <span style="margin-left: 100px;">
                -> Garantia de 3 anos para clientes não profissionalizados
              </span>
            </p>

            <p>
              <span style="margin-left: 100px;">
                -> Garantia de 1 ano para clientes empresariais
              </span>
            </p>

            <p>
              <span style="margin-left: 50px;">
                Equipamentos usados/recondicionados
              </span>
            </p>

            <p>
              <span style="margin-left: 100px;">
                -> Garantia de 18 meses para clientes não profissionalizados
              </span>
            </p>

            <p>
              <span style="margin-left: 100px;">
                -> Garantia de 1 ano (ou a garantia acordada) para clientes empresariais
              </span>
            </p>

            <p>
              <span style="margin-left: 50px;">
                Bens perecíveis (baterias, etc.) novos
              </span>
            </p>

            <p>
              <span style="margin-left: 100px;">
                -> Garantia de 6 meses
              </span>
            </p>

            <p>
              <span style="margin-left: 50px;">
                Bens perecíveis (baterias, etc.) usados/recondicionados
              </span>
            </p>

            <p>
              <span style="margin-left: 100px;">
                -> Garantia de 30 dias
              </span>
            </p>

            <p>
              <span style="margin-left: 50px;">
                Garantia de reparação de assistência técnica
              </span>
            </p>

            <p>
              <span style="margin-left: 100px;">
                -> Garantia de 3 meses, salvo se a avaria decorrer de um mau uso de utilização do equipamento por parte do cliente.
              </span>
            </p>

            <h3 style="text-decoration: underline;">
              Avarias
            </h3>

            <p>
              A garantia somente cobre defeitos de fabrico.
            </p>

            <p>
              A garantia cessa nos seguintes casos:
              <br>- Intervenções motivadas por causas estranhas ou alheias à Porto de Informática, Lda.
              <br>- Manutenção imprópria ou inadequada.
              <br>- Modificações não autorizadas ou uso incorreto.
              <br>- Operação fora das especificações ambientais, incluindo abuso de tempo de funcionamento, ou deficiências derivadas por picos de energia.
            </p>

            <p>
              A Porto de Informática não será responsável pelos prejuízos verificados na informação armazenada nos equipamentos avariados ou que sofra intervenção por motivos de assistência.
            </p>

            <p>
              É da responsabilidade do cliente guardar toda e qualquer informação antes do equipamento ser reparado.
            </p>

            <p>
              O prazo máximo para a reparação é de 30 dias.
            </p>

            <p>
              A Porto de Informática, Lda. em nenhuma circunstância incorrerá em atos ou ações ilegais ou que violem a lei, incluindo a violação de acordos de licenciamento de software, podendo exigir ao Cliente os respetivos licenciamentos de software ou termos de um qualquer contrato
            </p>

            <h3 style="text-decoration: underline;">
              Limite de Responsabilidade
            </h3>

            <p>
              Salvo a obrigação exposta específica nestas condições gerais, em qualquer circunstância a Porto de Informática, não será responsável por qualquer dano direto, indireto, especial, acidental ou consequentemente, mesmo que seja baseado em contracto, agravo ou qualquer outra teoria legal e mesmo que tenha sido avisada da responsabilidade de tais danos.
            </p>

            <p>
              A Renuncia a qualquer título destas condições gerais não pode ser interpretado como renúncia a outros direitos dele emergentes nem afetará a validade do mesmo, nem a possibilidade de fazer cumprir toadas as cláusulas.
            </p>

            <h3 style="text-decoration: underline;">
              Lei aplicável
            </h3>

            <p>
              As presentes condições gerais, bem como os contratos de venda celebrados ao abrigo das mesmas estão sujeitas à legislação portuguesa. e todos os conflitos emergentes deste contrato, serão eliminados no Tribunal da Comarca do Porto, com expressa renúncia a qualquer outro foro.
            </p>


          </div>
          <!-- End: Text -->
        </div>
      </div>
    </div>
  </div>
  <!-- End: Article Clean -->
  <main>
