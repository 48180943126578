    <app-header></app-header>

    <!-- Start: nav-info-lbl -->
    <div class="article-clean" style="margin-top: 69px;">
      <div class="container">
        <div class="row">
          <div class="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
            <!-- Start: Intro -->
            <div class="intro">
              <!-- Start: loc -->
              <p class="text-left">
                <span class="by">Produtos &gt; Comercio/Retalho</span>
              </p>
              <!-- End: loc -->
            </div>
            <!-- End: Intro -->
          </div>
        </div>
      </div>
    </div>
    <!-- End: nav-info-lbl -->
    <!-- Start: 1 Row 2 Columns -->
    <div>
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="card shadow-lg">
              <div class="card-body">
                <div class="product-header">
                  <img data-bs-hover-animate="bounce" src="../assets/img/wintouch_rest.png">
                  <div>
                    <h4>Software Wintouch<br></h4>
                    <h6 class="text-muted mb-2">Comercio/Retalho</h6>
                  </div>
                </div>
                <hr>
                <p class="text-justify card-text">
                  <br>WINTOUCH Retalho é um completo sistema de gestão concedido de raíz para responder aos vários tipos
                  de negócios de venda a retalho.
                  <br>Em conjunto com o ERP Wintouch, oferece às empresas um sistema completo e abrangente, cobrindo as
                  mais variadas áreas de negócio, sendo também uma ferramenta adaptável às várias dimensões de empresas.
                  <br>
                  <br>É uma solução standard, genérica mas que contempla, de base, especificações necessárias aos vários
                  tipos de negócios.
                  <br>
                </p>
                <hr>
                <div class="wintouch-rest-items">
                  <div class="text-center">
                    <h6>Mesas Balcao</h6>
                    <img src="../assets/img/mesas-balcao.png">
                  </div>
                  <div class="text-center">
                    <h6>Panificadoras</h6>
                    <img src="../assets/img/panif.png">
                  </div>
                  <div class="text-center">
                    <h6>Catering</h6>
                    <img src="../assets/img/wintouch-s3.png">
                  </div>
                  <div class="text-center">
                    <h6>Fast-food</h6>
                    <img src="../assets/img/wintouch-s4.png">
                  </div>
                  <div class="text-center">
                    <h6>Delivery</h6>
                    <img src="../assets/img/wintouch-s5.png">
                  </div>
                  <div class="text-center">
                    <h6>Bares/Disco</h6>
                    <img src="../assets/img/wintouch-s6.png">
                  </div>
                  <div class="text-center">
                    <h6>Term. Portateis</h6>
                    <img src="../assets/img/wintouch-s7.png">
                  </div>
                  <div class="text-center">
                    <h6>Dual display</h6>
                    <img src="../assets/img/wintouch-s8.png">
                  </div>
                  <div class="text-center">
                    <h6>Filiais</h6>
                    <img src="../assets/img/wintouch-s9.png">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-6">
            <div class="card shadow-lg">
              <div class="card-body">
                <div class="product-header">
                  <img data-bs-hover-animate="bounce" src="../assets/img/logo-xd-.png">
                  <div>
                    <h4>Software XD<br></h4>
                    <h6 class="text-muted mb-2">Comercio/Retalho<br></h6>
                  </div>
                </div>
                <hr>
                <p class="text-justify card-text">XD - O mais simples e intuitivo software para o retalho, do pequeno
                  comercio a uma rede de lojas o XD POS adapta-se às necessidades do seu negócio. Contemplando a gestão
                  standard de loja, possui módulos para sectores específicos
                  como Super Mercados, Gasolineiras, Cabeleireiros, SPA ou Lavandarias.Fléxivel
                  <br>Permite a instalação
                  em ambiente Touch Screen e/ou Teclado. Função totalmente dinâmica importa em tempo real todos os
                  artigos inseridos na
                  base de dados.Leitura de Código de Barras com Peso ou Preço incluído com possibilidade de configuração
                  de avançada da máscara. Códigos de Barras alternativos com indicação de Packs. Analise graficamente as
                  suas vendas com
                  um clique. Fecho cego com contagem de valor antes de impressão do fecho, indicação de fundo de maneio,
                  controle de entrada, saída e sangria. Configure preços e descontos especiais para um determinado
                  periodo de tempo É
                  possivel configurar campanhas para produtos especificos, familias e terminais. Possibilidade de pagar
                  uma venda em diversas modalidades de pagamento, por exemplo parte em numerário, parte em cartão visa.
                  Emissão de recibos
                  em A4, Extrato de conta corrente, Avisos de vencimento de documentos, Balancete e Mapa de Idade de
                  Saldos.
                  <br>Permite gerir conta corrente do cliente, lançar faturas e posteriormente emissão do recibo
                  para liquidação das
                  faturas vencidas, limitar créditos aos clientes e efetuar pagamentos parciais
                  <br>
                  <br>
                  <br>
                </p>
                <hr>
                <div class="d-flex justify-content-center">
                  <img src="../assets/img/xd-s1.png" style="margin-right: 16px;">
                  <img src="../assets/img/xd-s5.png" style="margin-left: 16px;">
                </div>
                <p class="text-justify">
                  <br>
                  <br>
                  <strong>Ligação a Balanças</strong>
                  <br>
                  Integração com diversas balanças
                  check-out com leitura automática do peso, permitindo aplicar desconto de tara.
                  <br>
                </p>
                <div class="d-flex justify-content-center">
                  <img src="../assets/img/xd-s6.png" style="margin-right: 16px;">
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- End: 1 Row 2 Columns -->

    <app-footer></app-footer>
