<app-loader *ngIf="loading"></app-loader>

<!-- Start: Header Full Screen -->
<div>
  <!-- Start: Menu + header background -->
  <header>
    <nav class="navbar navbar-light navbar-expand-lg fixed-top bg-white transparency border-bottom border-light"
      id="transmenu">
      <div class="container">
        <!-- Brand -->
        <a class="navbar-brand d-lg-flex align-items-lg-center end-hide">
          <i class="fa fa-at fa-2x"></i>
          Porto de Informatica, Lda.
        </a>
        <!--Botao menu para ecras pequenos -->
        <button data-toggle="collapse" class="navbar-toggler collapsed" data-target="#navcol-1">
          <span></span>
          <span></span>
          <span></span>
        </button>
        <!-- nav bar -->
        <div class="collapse navbar-collapse" id="navcol-1">
          <ul class="nav navbar-nav ml-auto">
            <li class="dropdown nav-item">
              <a class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false" href="#">Produtos</a>
              <div class="dropdown-menu" role="menu">
                <a class="dropdown-item" role="presentation" routerLink="products/rest">Restauração </a>
                <a class="dropdown-item" role="presentation" routerLink="products/ret">Comercio</a>
                <a class="dropdown-item" role="presentation" routerLink="products/hot">Hotelaria PMS</a>
                <!-- <a class="dropdown-item" role="presentation" routerLink="products/bd">Bares/Discotecas</a> -->
                <a class="dropdown-item" role="presentation" routerLink="products/health">Clinicas</a>
                <a class="dropdown-item" role="presentation" routerLink="products/erp">ERP</a>
                <a class="dropdown-item" role="presentation" routerLink="products/cctv">CCTV</a>
              </div>
            </li>

            <li class="nav-item" role="presentation">
              <a class="nav-link" routerLink="contacts">Contactos</a>
            </li>
            <!-- TODO: login em desenvolvimento -->
            <li class="nav-item hidden" role="presentation">
              <a class="nav-link" href="#">Loja</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <!-- Start: background + text -->
    <div class="d-flex align-items-center c-img">
      <!-- Start: Bold BS4 Full Page Image Slider Header -->
      <header style="width: 100%;height: 100%;">
        <div class="carousel slide" data-ride="carousel" id="carouselExampleIndicators">
          <div class="carousel-inner" role="listbox">
            <div class="carousel-item">
              <img class="w-100 d-block c-img" src="assets/img/register.png" alt="Slide Image" />
              <div class="carousel-caption d-none d-md-block">
                <h1 class="display-4">Desde 1997</h1>
                <p class="lead">A crescer juntos</p>
              </div>
            </div>
            <div class="carousel-item active">
              <img class="w-100 d-block c-img"
                style='background-image: url("https://source.unsplash.com/bF2vsubyHcQ/1920x1080");'
                src="assets/img/desk.jpg" alt="Slide Image" />
              <div class="carousel-caption d-none d-md-block">
                <h1 class="display-4">Evoluimos</h1>
                <p class="lead">com novas tecnologias.<br /></p>
              </div>
            </div>
            <div class="carousel-item">
              <img class="w-100 d-block c-img" src="assets/img/code.jpg" alt="Slide Image" />
              <div class="carousel-caption d-none d-md-block">
                <h1 class="display-4">Criamos</h1>
                <p class="lead">Software á sua medida</p>
              </div>
            </div>
          </div>
          <div>
            <!-- Start: Previous --><a class="carousel-control-prev" href="#carouselExampleIndicators" role="button"
              data-slide="prev"><span class="carousel-control-prev-icon"></span><span
                class="sr-only">Previous</span></a>
            <!-- End: Previous -->
            <!-- Start: Next --><a class="carousel-control-next" href="#carouselExampleIndicators" role="button"
              data-slide="next"><span class="carousel-control-next-icon"></span><span class="sr-only">Next</span></a>
            <!-- End: Next -->
          </div>
          <ol class="carousel-indicators">
            <li data-target="#carouselExampleIndicators" data-slide-to="0"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="1" class="active"></li>
            <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
          </ol>
        </div>
      </header>
      <!-- End: Bold BS4 Full Page Image Slider Header -->
    </div>
    <!-- End: background + text -->
  </header>
  <!-- End: Menu + header background -->
</div>
<!-- End: Header Full Screen -->

<!-- Start: Presentation text -->
<div class="article-clean">
  <div class="container">
    <div class="row">
      <div class="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
        <!-- Start: Intro -->
        <div class="intro">
          <h1 class="text-center" id="about" href="#">
            O seu Parceiro Estratégico<br />
          </h1>
        </div>
        <!-- End: Intro -->
        <!-- Start: Text -->
        <div class="text">
          <p class="text-justify">
            A Porto de Informática foi fundada em 1997, por antigos funcionários
            da Regisconta.<br />Possuindo largos anos de experiência no segmento
            da Hotelaria, restauração e comércio/retalho, proporcionamos
            soluções informáticas que rentabilizem o negócio através de metódos
            de controlo operacional ajustados às necessidades e forma de
            trabalho de cada empresa. <br />Identificamos as necessidades,
            implementamos o sistema e disponibilizamos o suporte técnico
            adequado.<br />Sabendo que negócios como a restauração e
            comércio/retalho não podem parar, temos ao dispôr do cliente um
            serviço de piquete de técnicos credenciados disponíveis para
            satisfazer atempadamente qualquer pedido de assistência mesmo fora
            do período normal de trabalho (Ex: Fins de semana, feriados e
            noites).<br />Tendo na carteira de clientes alguns dos melhores
            clientes dessas areas especificas, procuramos no dia a dia, melhorar
            e proporcionar condições de funcionamento ininterruptas de maneira a
            que a solução informática disponibilizada seja uma ferramenta útil e
            sempre operacional.<br />Contar com a Porto de Informática como
            parceiro estrategico, é uma garantia responsável de sucesso e de
            continuidade.<br /><br />Carlos Frias<br />
          </p>
        </div>
        <!-- End: Text -->
      </div>
    </div>
  </div>
</div>
<!-- End: Presentation text -->

<!-- Start: Parallax -->
<div data-bs-parallax-bg="true"
  style='height: 286px;background-image: url("assets/img/porto.jpg");background-position: center;background-size: cover;'>
</div>
<!-- End: Parallax -->

<!-- Start: newsletter-sub -->
<div class="newsletter-subscribe">
  <div class="container">
    <!-- Start: Intro -->
    <div class="intro">
      <h2 class="text-center">Subscreva a nossa Newsletter.</h2>
      <p class="text-center">
        Seja o primeiro a saber as novidades sobre o nosso software!&nbsp;
      </p>
      <!-- Start: alert-success -->
      <div class="alert alert-success" role="alert" *ngIf="newsLetterResult && newsLetterResult.success">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">×</span></button><span><strong>Sucesso</strong>
          {{ newsLetterResult.message }}.</span>
      </div>
      <!-- End: alert-success -->
      <!-- Start: alert-error -->
      <div class="alert alert-danger" *ngIf="newsLetterResult && !newsLetterResult.success">
        <button type="button" class="close" aria-label="Close">
          <span (click)="clearMessages()">×</span>
        </button>
        <span><strong>Erro</strong> {{ newsLetterResult.message }}.</span>
      </div>
      <!-- End: alert-error -->
    </div>
    <!-- End: Intro -->
    <form class="form-inline">
      <div class="form-group">
        <input class="form-control" #email type="email" name="email" placeholder="O seu email" />
      </div>
      <div class="form-group">
        <button class="btn btn-primary" type="button" (click)="nlSubscribe(email.value)">
          Subscreva
        </button>
      </div>
    </form>
  </div>
</div>
<!-- End: newsletter-sub -->

<div class="cookie-consent-banner" *ngIf="!cookies">
  <div class="cookie-consent-banner__inner">
    <div class="cookie-consent-banner__copy">
      <div class="cookie-consent-banner__header">ESTE WEB SITE USA COOKIES</div>
      <div class="cookie-consent-banner__description">O nosso website utiliza cookies que são ficheiros que armazenam informações no disco rígido ou browser do Utilizador, permitindo que o website o reconheça e saibam que já os visitou anteriormente.Os cookies são utilizados para monitorizar e analisar a utilização do website.A PORTO DE INFORMÁTICA, LDA. não partilha cookies gerados neste website.</div>
    </div>

    <div class="cookie-consent-banner__actions">
      <a (click) ="acceptCookies()" class="cookie-consent-banner__cta">
        OK
      </a>

      <!-- <a href="#" class="cookie-consent-banner__cta cookie-consent-banner__cta--secondary">
        Decline
      </a> -->
    </div>
  </div>
</div>

<app-footer></app-footer>
