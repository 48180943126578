import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { environment } from '../../environments/environment';

/**
 * Interface repostas do serviço
 */
export interface INewsletterServiceMessage {
  success: boolean;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class NewsLetterService {

  constructor(private http: HttpClient) { }

  // Observable
  private commResult = new BehaviorSubject<INewsletterServiceMessage>(null);
  commResultObs = this.commResult.asObservable();

  /**
   * Subscrição da newsletter
   * @param email email a subscrever
   */
  join(email: string) {
    // Cria o objecto de resposta
    const response = {} as INewsletterServiceMessage;
    // Faz o pedido á api
    this.http
      .post<{ message: string }>(environment.apiUrl + '/api/newsletter/subscriptions/subscribe', { email })
      .subscribe(result => {
        // Sucesso
        // Preenchimento dos dados da resposta
        response.success = true;
        response.message = result.message;
        // Notifica da resposta
        this.commResult.next(response);
      }, fail => {
        // Erro
        // Preenchimento dos dados do erro
        response.success = false;
        response.message = fail.error.message
        // Notifica o erro
        this.commResult.next(response);
      });
  }

  unJoin(email: string) { }
}
