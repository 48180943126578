import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { ContactsComponent } from './contacts/contacts.component';
import { RestComponent } from './products/rest/rest.component';
import { RetComponent } from './products/ret/ret.component';
import { HotComponent } from './products/hot/hot.component';
import { BdComponent } from './products/bd/bd.component';
import { HealthComponent } from './products/health/health.component';
import { ErpComponent } from './products/erp/erp.component';
import { CctvComponent } from './products/cctv/cctv.component';
import { CopyrightComponent } from './copyright/copyright.component';
import { PrivacyComponent } from './privacy/privacy.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'contacts', component: ContactsComponent },
  { path: 'copyright', component: CopyrightComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'products/rest', component: RestComponent },
  { path: 'products/ret', component: RetComponent },
  { path: 'products/hot', component: HotComponent },
  { path: 'products/bd', component: BdComponent },
  { path: 'products/health', component: HealthComponent },
  { path: 'products/erp', component: ErpComponent },
  { path: 'products/cctv', component: CctvComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
