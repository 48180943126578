<!-- Start: Header Full Screen -->
<div>
  <!-- Start: Menu + header background -->
  <header>
    <nav class="navbar navbar-light navbar-expand-lg fixed-top bg-white transparency border-bottom border-light">
      <div class="container">
        <!-- Brand -->
        <a class="navbar-brand d-lg-flex align-items-lg-center end-hide">
          <i class="fa fa-at fa-2x"></i>
          Porto de Informatica, Lda
        </a>
        <!--Botao menu para ecras pequenos -->
        <button data-toggle="collapse" class="navbar-toggler collapsed" data-target="#navcol-1">
          <span></span>
          <span></span>
          <span></span>
        </button>
        <!-- nav bar -->
        <div class="collapse navbar-collapse" id="navcol-1">
          <ul class="nav navbar-nav ml-auto">
            <li class="nav-item" role="presentation">
              <a class="nav-link" routerLink="/">Home</a>
            </li>
            <li class="dropdown nav-item">
              <a class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false" href="#">Produtos</a>
              <div class="dropdown-menu" role="menu">
                <a class="dropdown-item" role="presentation" routerLink="/products/rest">Restauração </a>
                <a class="dropdown-item" role="presentation" routerLink="/products/ret">Comercio</a>
                <a class="dropdown-item" role="presentation" routerLink="/products/hot">Hotelaria PMS</a>
                <!-- <a class="dropdown-item" role="presentation" routerLink="/products/bd">Bares/Discotecas</a> -->
                <a class="dropdown-item" role="presentation" routerLink="/products/health">Clinicas</a>
                <a class="dropdown-item" role="presentation" routerLink="/products/erp">ERP</a>
                <a class="dropdown-item" role="presentation" routerLink="/products/cctv">CCTV</a>
              </div>
            </li>
            <!-- TODO: Serviços em desenvolvimento -->
            <li class="dropdown nav-item hidden">
              <a class="dropdown-toggle nav-link" data-toggle="dropdown" aria-expanded="false" href="#">Serviços</a>
              <div class="dropdown-menu" role="menu">
                <a class="dropdown-item" role="presentation" href="#">Web Design</a><a class="dropdown-item"
                  role="presentation" href="#">Data Center</a><a class="dropdown-item" role="presentation"
                  href="#">MSSQL Backup</a><a class="dropdown-item" role="presentation" href="#">RestMenu</a><a
                  class="dropdown-item" role="presentation" href="#">HotelMessenger</a>
              </div>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" routerLink="/contacts">Contactos</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

  </header>
  <!-- End: Menu + header background -->
</div>
<!-- End: Header Full Screen -->
