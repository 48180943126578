<!-- Start: Footer Dark -->
<div class="footer-dark" style="margin-top: 16px">
  <footer>
    <div class="container">
      <div class="row">
        <!-- Start: Footer Text -->
        <div class="col-md-6 item text">
          <h3>Porto de Informatica, Lda</h3>
          <p>
            Rua Paulo da Gama 162 <br />4435-382 Rio Tinto, Portugal.
            <br />Tel/Fax: 351-225089801 (Chamada para a rede
            fixa Nacional) <br />E-mail: portoinf@sapo.pt<br />
          </p>
        </div>
        <!-- End: Footer Text -->
        <!-- Start: Social Icons -->
        <div class="col align-self-center item social">
          <a href="https://www.facebook.com/PortoDeInformatica/">
            <i class="icon ion-social-facebook"></i>
          </a>
          <a href="https://www.instagram.com/porto_informatica/?hl=pt">
            <i class="icon ion-social-instagram"></i>
          </a>
        </div>
        <!-- End: Social Icons -->
      </div>
      <!-- Start: Copyright -->
      <p class="copyright">Porto de Informática © 2023 V. 1.1.1104</p>
      <p></p>
      <div style="width: 100%; text-align: center">
        <a href="https://www.livroreclamacoes.pt/inicio">
          <img src="assets/img/lrec.png" alt="" />
        </a>
      </div>
      <!-- End: Copyright -->
      <p class="copyright">
        <a style="color: #f0f9ff" routerLink="copyright">Termos e condições</a>
        |
        <a style="color: #f0f9ff" routerLink="privacy">Politica de Privacidade</a>
      </p>

    </div>
  </footer>
</div>
<!-- End: Footer Dark -->