<app-header></app-header>

<main class="content">
     <!-- Start: nav-info-lbl -->
     <div class="article-clean" style="margin-top: 69px;">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 col-xl-8 offset-lg-1 offset-xl-2">
                        <!-- Start: Intro -->
                        <div class="intro">
                            <!-- Start: loc -->
                            <p class="text-left"><span class="by">Produtos &gt; CCTV</span> </p>
                            <!-- End: loc -->
                        </div>
                        <!-- End: Intro -->
                    </div>
                </div>
            </div>
        </div>
        <!-- End: nav-info-lbl -->
        <div class="card shadow-lg">
            <div class="card-body">
                <div class="product-header"><img data-bs-hover-animate="bounce" src="../assets/img/cctv_logo.png">
                    <div>
                        <h4>Sistemas de Videvigilância<br></h4>
                    </div>
                </div>
                <hr>
                <p class="text-justify card-text">O sistemas de CCTV são constituídos por um conjunto de câmaras colocadas em lugares estratégicos, que captam e transmitem imagens para um sistema de gestão de vídeo que permite entre outras coisas a visualização e gravação dessas mesmas imagens.<br><br>A
                    colocação de câmaras visíveis em pontos estratégicos dissuade qualquer intenção de delito e proporciona aos visitantes um sentimento de segurança.<br><br>Disponibilizamos vários tipos de câmaras e soluções (Box, Tubulares, Falsas, Speed
                    Domes, Domes e por IP)<br><br><br></p>
                <hr>
            </div>
        </div>
        <!-- Start: Lightbox Gallery -->
        <div class="photo-gallery">
            <div class="container">
                <!-- Start: Intro -->
                <div class="intro">
                    <h2 class="text-center">Lightbox Gallery</h2>
                    <p class="text-center">Nunc luctus in metus eget fringilla. Aliquam sed justo ligula. Vestibulum nibh erat, pellentesque ut laoreet vitae. </p>
                </div>
                <!-- End: Intro -->
                <!-- Start: Photos -->
                <div class="row photos">
                    <div class="col-sm-6 col-md-4 col-lg-3 item"><a data-lightbox="photos" href="../assets/img/cctv1.jpg"><img class="img-fluid" src="../assets/img/cctv1.jpg"></a></div>
                    <div class="col-sm-6 col-md-4 col-lg-3 item"><a data-lightbox="photos" href="../assets/img/cctv2.jpg"><img class="img-fluid" src="../assets/img/cctv2.jpg"></a></div>
                    <div class="col-sm-6 col-md-4 col-lg-3 item"><a data-lightbox="photos" href="../assets/img/cctv3.jpg"><img class="img-fluid" src="../assets/img/cctv3.jpg"></a></div>
                    <div class="col-sm-6 col-md-4 col-lg-3 item"><a data-lightbox="photos" href="../assets/img/cctv4.jpg"><img class="img-fluid" src="../assets/img/cctv4.jpg"></a></div>
                </div>
                <!-- End: Photos -->
            </div>
        </div>
        <!-- End: Lightbox Gallery -->
</main>

<app-footer></app-footer>
